<template>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <form @submit.prevent="handleSubmit" action="#">
            <h4 class="header-title">General Information</h4>
            <p class="sub-header">Fill all information below</p>

            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mb-3">
                  <label for="product-name">
                    Vehicle name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                      type="text"
                      id="product-name"
                      class="form-control"
                      placeholder="e.g : Toyota Corolla"
                      v-model="carName"
                      required
                      :class="{ 'is-invalid': submitted && $v.carName.$error }"
                  />
                  <div v-if="submitted && !$v.carName.error" class="invalid-feedback">This value is required.</div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label for="kmPerDay">
                    Kilometers per day
                    <span class="text-danger">*</span>
                  </label>
                  <input
                      type="text"
                      id="kmPerDay"
                      class="form-control"
                      placeholder="Kilometers per day"
                      v-model="kmPerDay"
                      required
                  />

                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label for="priceOfKmPerDay">
                    Price per extra km
                    <span class="text-danger">*</span>
                  </label>
                  <input
                      type="number"
                      step=".01"
                      min="0"
                      v-model="priceOfKmPerDay"
                      id="priceOfKmPerDay"
                      class="form-control"
                      placeholder="e.g : 600"
                      required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label for="kmPerDay">
                    Fuel's unit of measurement
                    <span class="text-danger">*</span>
                  </label>
                  <select name="" class="form-control" v-model="unitOfFuel" required>
                    <option value="">-- Select one --</option>
                    <option value="litre">Litres</option>
                    <option value="watt">Watts</option>
                  </select>

                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label>
                    Price per {{unitOfFuel || 'unit'}}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                      type="number"
                      step=".01"
                      min="0"
                      v-model="pricePerUnitOfFuel"
                      class="form-control"
                      placeholder="e.g : 600"
                      required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="notes"> Optional Notes </label>
                  <textarea id="notes" v-model="carNotes" class="form-control" placeholder="Any Additional notes" rows="4"></textarea>
                </div>
              </div>
            </div>

            <hr>
            <h4 class="header-title">Vehicle Images</h4>
            <p class="sub-header">Upload product image</p>
            <div class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <vue-dropzone
                      id="mainImage"
                      ref="mainImage"
                      :use-custom-slot="true"
                      :options="mainImageDropzoneOptions"
                      @vdropzone-max-files-exceeded="mainImageMaxFileExceeded"
                  >
                    <div class="dz-message needsclick">
                      <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                      <h3>Upload main image</h3>
                    </div>
                  </vue-dropzone>
                </div>
                <div class="col-md-8">
                  <vue-dropzone
                      id="supportingImages"
                      ref="supportingImage"
                      :use-custom-slot="true"
                      :options="supportingImageDropzoneOptions"
                      @vdropzone-max-files-exceeded="supportingImageMaxFileExceeded"
                  >
                    <div class="dz-message needsclick">
                      <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                      <h3>Drop supporting images here or click to upload.</h3>

                    </div>
                  </vue-dropzone>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">

                    <label for="stateOfCarImages">Upload car state (will show in departure controls)</label>
                    <vue-dropzone
                        id="stateOfCarImages"
                        ref="stateOfCarImages"
                        :use-custom-slot="true"
                        :options="dropzoneOptionsStateOfCar"
                    ><div class="dz-message needsclick">
                      <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                      <h3>No images uploaded yet</h3>
                    </div>
                    </vue-dropzone>


                </div>
              </div>
            </div>

            <hr>
            <div class="text-right">
              <b-button class="btn btn-soft-danger" @click="addNewSpecification">Add Specification Field</b-button>
            </div>
            <h4 class="header-title">Specifications</h4>
            <p class="sub-header">Add specifications to the vehicle</p>

            <div v-for="property in specifications">
              <div class="form-group mb-3 mt-3">
                <div class="row">

                  <div class="col-md-6">
                    <label :for="'field-' + property.id">Field</label>
                    <input
                        type="text"
                        class="form-control"
                        :id="'field-' + property.id"
                        placeholder="Enter field name"
                        v-model="property.title"
                        :disabled="property.id === 0"
                    />
                  </div>
                  <div class="col-md-6">
                    <label :for="'value-' + property.id">Value</label>
                    <div class="input-group input-group-merge">
                      <input type="text"
                             :id="'value-' + property.id"
                             class="form-control"
                             placeholder="Enter value here"
                             v-model="property.value"
                      />
                      <div v-if="property.id !== 0" class="input-group-append" @click="removeSpecification">
                        <div class="input-group-text bg-danger">
                          <span class="fa fa-trash text-white"></span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

            </div>

            <hr>
            <div class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <b-button type="submit" class="btn btn-success ml-1 m-lg-1"> Submit </b-button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vue2Dropzone from "vue2-dropzone";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../../app.config";
import {required} from "vuelidate/lib/validators";

/**
 * Product-create component
 */
export default {
  validations: {
    carName: {
      required
    },

  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    vueDropzone: vue2Dropzone,
  },
  computed:{
    car(){
      return this.$store.getters.GET_SELECTED_CAR;
    }

  },
  data() {
    return {
      submitted: false,

      carName: '',
      carNotes: '',
      specifications: '',
      kmPerDay: '',
      priceOfKmPerDay: '',
      unitOfFuel: '',
      pricePerUnitOfFuel: '',

      mainImageDropzoneOptions: {
        url: '/',
        addRemoveLinks: true,
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        autoProcessQueue: false,
        maxFiles: 1,
      },

      supportingImageDropzoneOptions: {
        url: '/',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 3,
      },

      dropzoneOptionsStateOfCar: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 20,
      },

    };
  },
  middleware: "router-auth",

  created() {
    const carId = this.$route.params.id;
    this.$store.dispatch('getCarDetail', carId);
  },

  watch: {
    car: function (newValue, oldValue){
      if(newValue){

        // set values
        this.setFields();

        let file = { size: this.car.mainImage.size, name: this.car.mainImage.name, type: this.car.mainImage.type };
        let url = this.car.mainImage.url;
        this.$refs.mainImage.manuallyAddFile(file, url);

        if(this.car.supportingImages){

          this.car.supportingImages.forEach(e => {

            let file = { size: e.size, name: e.name, type: e.type };
            let url = e.url;
            this.$refs.supportingImage.manuallyAddFile(file, url);
          })

        }

        if(this.car.stateOfCar){

          this.car.stateOfCar.forEach(e => {

            let file = { size: e.size, name: e.name, type: e.type };
            let url = e.url;
            this.$refs.stateOfCarImages.manuallyAddFile(file, url);

          })
        }


      }
    }
  },

  methods: {

    addNewSpecification(){
      this.specifications.push({
        id: this.specifications.length,
        title: '',
        value: ''
      })
    },


    setFields(){
      this.carName = this.car.name
      this.carNotes = this.car['notes'];
      this.specifications = this.car['specifications'];
      this.kmPerDay = this.car['km_per_day'];
      this.priceOfKmPerDay = this.car['km_per_day_price'];
      this.unitOfFuel = this.car['unit_of_fuel']
      this.pricePerUnitOfFuel = this.car['price_per_unit_of_fuel']

    },

    removeSpecification(id){
      if (id === 0)
        return;
      let specIndex = this.specifications.findIndex(el => el.id === id)
      this.specifications.splice(specIndex,1)
    },

    handleSubmit(e){

      // stop here if form is invalid
      // this.$v.$touch()
      //
      // if(this.$v.$invalid)
      //   return;

      let mainImage = this.$refs.mainImage.getAcceptedFiles();
      let supportingFiles = this.$refs.supportingImage.getAcceptedFiles();
      let stateOfCarImages = this.$refs.stateOfCarImages.getAcceptedFiles();

      const formData = new FormData();
      formData.append("main_image", mainImage);

      supportingFiles.forEach(el => {
        formData.append("supporting_images[]", el);
      })

      stateOfCarImages.forEach(el => {
        formData.append("car_state_images[]", el);
      })

      formData.append("name", this.carName);
      formData.append('notes', this.carNotes);
      formData.append('km_per_day', this.kmPerDay);
      formData.append('km_per_day_price', this.priceOfKmPerDay);
      formData.append('unit_of_fuel', this.unitOfFuel);
      formData.append('price_per_unit_of_fuel', this.pricePerUnitOfFuel);

      formData.append("specifications", JSON.stringify(this.specifications));

      this.$store.dispatch('updateCar', {
        carId: this.car.id,
        formData
      })
    },

    mainImageMaxFileExceeded(file){
      this.$refs.mainImage.removeFile(file);
    },

    supportingImageMaxFileExceeded(file){
      console.log("supporting images file exceeded: " , file)
      this.$refs.supportingImage.removeFile(file);
    }

  },

};
</script>

<style >
#myDropzone .dz-preview .dz-progress {
  opacity: .50;
}
</style>